import React, { useEffect } from "react";

import Page from "../../components/page/page.component";
import Login from "../../components/login/login.component";

import { LoggedOutWrapper, InnerWrapper } from "./virtualhrpage.styles";

const VirtualHRPage = ({ data, setToken, token }) => {
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    console.log("Loged in User", loggedInUser);
    console.log("HI");
    if (loggedInUser) {
      setToken(loggedInUser);
    } else {
      // console.log(state.theme.token);
    }
  }, [token]);

  return (
    <>
      <Page data={data} />
      {token ? null : (
        <LoggedOutWrapper>
          <InnerWrapper>
            <Login setToken={setToken} />
          </InnerWrapper>
        </LoggedOutWrapper>
      )}
    </>
  );
};

export default VirtualHRPage;
