import styled from "@emotion/styled";

export const VideoTile = styled.div`
  position: relative;
  transition: 0.3s all;
  &:hover {
    // border-left: 5px solid red;
    transform: scale(1.03);
    background: white;
  }

  border-bottom: 3px solid var(--colors-menuBlue);

  img {
    height: 100%;
    aspect-ratio: 3/1.935;
    object-fit: cover;
  }
`;

export const VideoTitleContainer = styled.div`
  position: absolute;
  width: 100%;
  background: var(--colors-site-overlay);
  color: var(--colors-site-white);
  padding: 10px 15px;
  bottom: 0;
  left: 0;
  text-align: left;
  font-weight: 600;
`;
