import { Link } from "react-router-dom";
import React from "react";
import dayjs from "dayjs";

import { myunescape } from "../../utils/utility-functions";
import LogoContainer from "../../components/logo-container/logo-container.component";
import Spinner from "../../components/spinner/spinner.component";

import newslineHeader from "../../static/images/Newsline_Header.png";

import {
  CalendarWrapper,
  DateContainer,
  Date,
  Day,
  EventContainer,
  EventList,
  EventTitle,
  CallToAction,
  CaresInfo,
  CaresPageWrapper,
  CaresTile,
  CaresTileWrapper,
  FeaturedContainer,
  FeaturedImage,
  ImageList,
  InfoContainer,
  LogoWrapper,
  Month,
  TimeContainer,
} from "./blogpage.styles";

const BlogPage = ({ blogPosts }) => {
  const mappedCaresList = () => {
    // console.log("MAPPED LIST", blogPosts);
    const list = blogPosts.map((items) => {
      return (
        <div key={items.id}>
          <Link to={`${items.id}`}>
            <CaresTile>
              <FeaturedContainer>
                <img src={items.fimg_url} />
              </FeaturedContainer>
              <CaresInfo>
                <h4>{myunescape(items.title.rendered)}</h4>
                <p>
                  {dayjs(`${items.date}`).format("M/DD/YYYY").toUpperCase()}
                </p>
              </CaresInfo>
            </CaresTile>
          </Link>
        </div>
      );
    });
    // console.log("list", list);
    return list.filter((el) => el !== undefined);
  };

  return (
    <>
      <CaresPageWrapper>
        <LogoWrapper>
          <LogoContainer
            className="centered"
            source={newslineHeader}
            alt="ABC Newsline Header"
            widthValue="800px"
            heightValue="auto"
            link="/"
            margin="0"
          />
        </LogoWrapper>
        <h2>What's Happening at ABC Greater Baltimore</h2>
        <br />
        {/* <CallToAction>
          <strong>GOT GOOD DEEDS?</strong> We want to hear about them! Send us a
          brief write-up and photos of the charitable event or project and we
          will include in future magazines and on our ABCCares website. Please
          send your material to{" "}
          <a target="_blank" href="mailto:mpusateri@abcbaltimore.org">
            Mandi Pusateri
          </a>
        </CallToAction> */}
        <ImageList>
          {blogPosts && blogPosts.length > 0 ? (
            <CaresTileWrapper>{mappedCaresList()}</CaresTileWrapper>
          ) : (
            <Spinner />
          )}
        </ImageList>
      </CaresPageWrapper>
    </>
  );
};

export default BlogPage;
