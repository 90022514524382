import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const CardBlock = styled.div`
  width: 80vw;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;

  @media screen and (max-width: 1100px) {
    width: 100vw;
  }
`;

export const Card = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  height: 300px;
  flex: 0 1 300px;
  background-color: var(--colors-site-mainTheme);

  @media screen and (max-width: 1100px) {
    flex: 1 1 250px;
  }
`;

export const CardImage = styled.img`
  width: 250px;
  height: 250px;
  object-fit: contain;
`;

export const DualCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  height: 300px;
  flex: 0 1 300px;
  background-color: var(--colors-site-mainTheme);
  color: var(--colors-site-lightAccent);

  @media screen and (max-width: 1100px) {
    flex: 1 1 250px;
  }
`;

export const DualCardImage = styled.img`
  width: 250px;
  height: 100px;
  object-fit: cover;
`;
