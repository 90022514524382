import styled from "@emotion/styled";
import { css } from "@emotion/css";

import membershipbg from "../../static/images/blur1.jpg";
import apprenticeshipbg from "../../static/images/blur3.jpg";
import eventsbg from "../../static/images/events-bg.jpg";
import safetybg from "../../static/images/blur5.jpg";
import managementbg from "../../static/images/blur2.jpg";
import politicalbg from "../../static/images/blur4.jpg";
import membersbg from "../../static/images/blur7.jpg";
import abclogo from "../../static/images/abc-logo-new.png";

import membershipbgmid from "../../static/images/1.jpg";
import apprenticeshipbgmid from "../../static/images/apprenticeship_bgmid.jpg";
import eventsbgmid from "../../static/images/events_bgmid.jpg";
import safetybgmid from "../../static/images/safety_bgmid.jpg";
import managementbgmid from "../../static/images/management_bgmid.jpg";
import politicalbgmid from "../../static/images/political_bgmid.jpg";

import membershipbgmobile from "../../static/images/1.jpg";
import apprenticeshipbgmobile from "../../static/images/apprenticeship_bgmobile.jpg";
import eventsbgmobile from "../../static/images/events_bgmobile.jpg";
import safetybgmobile from "../../static/images/safety_bgmobile.jpg";
import managementbgmobile from "../../static/images/management_bgmobile.jpg";
import politicalbgmobile from "../../static/images/political_bgmobile.jpg";

export const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--colors-site-overlay);
  z-index: -1;

  @media screen and (max-width: 1100px) {
    // top: 50px;
  }
`;
export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--screen-height);
  background-size: cover;
  background-position: right;
  z-index: -1;
  overflow-x: hidden;

  &.membership {
    background-image: url(${membershipbg});
  }
  &.apprenticeship {
    background-image: url(${apprenticeshipbg});
  }
  &.events {
    background-image: url(${eventsbg});
  }
  &.safety {
    background-image: url(${safetybg});
  }
  &.management {
    background-image: url(${managementbg});
  }
  &.political {
    background-image: url(${politicalbg});
  }
  &.members {
    background-image: url(${membersbg});
  }
  &.white {
    // background-image: url(${abclogo});
    // background-repeat: repeat;
    // background-size: 150px;
    // background-position: left center;
    // opacity: 0.05;
  }

  @media screen and (max-width: 1100px) {
    // top: 50px;
    bottom: 0px;
  }
`;
