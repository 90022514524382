import React, { useEffect, useState } from "react";
import { BlogWrapper, GlassWrap, NewsTitle, LeftBar } from "./blog.styles";
import { Link } from "react-router-dom";
import { myunescape } from "../../utils/utility-functions";

const Blog = ({ blogPosts }) => {
  const [blogPost, setBlogPost] = useState();

  const blogID = window.location.pathname.split("/")[2];
  // console.log("blog ID", blogID)

  useEffect(() => {
    if (blogPosts.length > 0) {
      // console.log(state.theme.blogPosts);

      blogPosts.map((el) => {
        // console.log(el.id)
        if (el.id.toString() === blogID) {
          // console.log("ELEMENT", el)
          setBlogPost(el);
        }
      });

      // console.log("blog Post", blogPost)
    }
  }, [blogPosts]);

  return (
    <>
      <LeftBar />
      {blogPost !== undefined ? (
        <GlassWrap>
          <BlogWrapper>
            <NewsTitle>- INDUSTRY NEWS -</NewsTitle>
            <img src={blogPost.fimg_url} />
            <br />
            <Link to="/news">
              <h4>&#x2190; See Other Stories</h4>
            </Link>
            <br />
            <h3>{myunescape(blogPost.title.rendered)}</h3>
            <br />
            <div
              dangerouslySetInnerHTML={{ __html: blogPost.content.rendered }}
            />
          </BlogWrapper>
        </GlassWrap>
      ) : null}
    </>
  );
};

export default Blog;
