import React, { useState } from "react";
import { useForm, Controller, set } from "react-hook-form";
import { redirect } from "react-router-dom";

import Page from "../../components/page/page.component";
import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import Spinner from "../../components/spinner/spinner.component";

import {
  Wrapper,
  FormWrapper,
  ErrorMessage,
  InputLabel,
  SuccessMessage,
  GlassWrap,
  TextArea,
} from "./logicinterestpage.styles";

const LogicInterestPage = ({ data }) => {
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      input_7: "",
      input_2: "",
      input_4: "",
      input_5: "",
      input_6: "",
    },
  });

  const onSubmit = (datavalue) => {
    setSuccess();
    setProcessing(true);
    const formElement = document.forms["logicinterestform"],
      { action, method } = formElement,
      formData = new FormData(formElement);

    for (var value of formData.entries()) {
      console.log(value);
    }

    // let newResumeData = {};

    // formData.forEach((value, key) => {
    //   newResumeData[key] = value;
    // });

    // setResumeData(newResumeData);

    const options = {
      method,
      body: formData,
    };
    console.log("OPTIONS", options);

    fetch(action, options)
      .then((response) => response.json())
      .then((response) => {
        setProcessing(false);
        // console.log(response)

        if (!response.is_valid) {
          setSuccess(false);
        } else {
          setSuccess(true);
          //   return redirect("/resume-print");
        }
      })
      .catch((error) => {});
  };

  return (
    <Wrapper>
      <Page data={data} />
      <GlassWrap>
        {success ? (
          <SuccessMessage>
            Thank you for your Submission. Someone will contact you soon.
          </SuccessMessage>
        ) : (
          <FormWrapper>
            <h4>Contact Information</h4>

            <form
              id="logicinterestform"
              onSubmit={handleSubmit(onSubmit)}
              action="https://dev.abcbaltimore.org/wp-json/gf/v2/forms/18/submissions"
              method="post"
            >
              <Controller
                control={control}
                name="input_7"
                rules={{ required: true }}
                render={({ field }) => <FormInput {...field} label="*Name" />}
              />
              {errors.input_7?.type === "required" && (
                <ErrorMessage>"Name is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_2"
                rules={{
                  required: true,
                  pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                }}
                render={({ field }) => (
                  <FormInput {...field} label="*Email Address" />
                )}
              />
              {errors.input_2?.type === "required" && (
                <ErrorMessage>"Email is required"</ErrorMessage>
              )}
              {errors.input_2?.type === "pattern" && (
                <ErrorMessage>
                  "Please Enter a Valid Email Address"
                </ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_4"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormInput {...field} label="*Company" />
                )}
              />
              {errors.input_4?.type === "required" && (
                <ErrorMessage>"Company name is Required"</ErrorMessage>
              )}

              <Controller
                control={control}
                name="input_5"
                rules={{
                  required: true,
                  pattern:
                    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                }}
                render={({ field }) => (
                  <FormInput {...field} label="*Phone Number" />
                )}
              />
              {errors.input_5?.type === "required" && (
                <ErrorMessage>"Phone Number is Required"</ErrorMessage>
              )}
              {errors.input_5?.type === "pattern" && (
                <ErrorMessage>"Phone Number Not Valid"</ErrorMessage>
              )}

              <p>LOGIC Member Information Request</p>
              <Controller
                control={control}
                name="input_6"
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <input
                      {...field}
                      id="first"
                      type="radio"
                      value="Add me to the LOGIC distribution list (ABC members only)"
                    />
                    <label htmlFor="first">
                      Add me to the LOGIC distribution list (ABC members only)
                    </label>
                    <br />
                    <input
                      {...field}
                      id="second"
                      type="radio"
                      value="I would like to learn more information about LOGIC and ABC membership"
                    />
                    <label htmlFor="second">
                      I would like to learn more information about LOGIC and ABC
                      membership
                    </label>
                    <br />
                  </>
                )}
              />
              <br />
              {errors.input_6?.type === "required" && (
                <ErrorMessage>"Interest Type is Required"</ErrorMessage>
              )}
              <br />
              <CustomButton>Submit Form</CustomButton>
              <br />
              <br />
              {isSubmitted && isSubmitSuccessful === false ? (
                <ErrorMessage>Please check the form for errors</ErrorMessage>
              ) : null}
            </form>
            {processing ? <Spinner /> : null}
            {success === false ? (
              <ErrorMessage>
                There was an error processing your information. Please try again
                or contact ABC for help.
              </ErrorMessage>
            ) : null}
          </FormWrapper>
        )}
      </GlassWrap>
    </Wrapper>
  );
};

export default LogicInterestPage;
