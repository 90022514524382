import React, { useState, useMemo } from "react";

import {
  Break,
  CardHeading,
  Column,
  EventsBackground,
  EventsItemsContainer,
  GradientDiv,
  GridWrapper,
  GrowContainer,
  HorizontalLine,
  ItemList,
  ItemTitle,
  ListItem,
  ManagementBackground,
  ManagementItemsContainer,
  MembersBackground,
  MembersItemsContainer,
  MembershipBackground,
  MembershipItemsContainer,
  MenuContainer,
  MobileBreak,
  PoliticalBackground,
  PoliticalItemsContainer,
  SafetyBackground,
  SafetyItemsContainer,
  Wrapper,
} from "./mobile-menu.styles";

const MobileMenu = ({ setHamburgerToggle, token, setToken }) => {
  // useEffect(() => {
  //   if (window.location.pathname === "/") {
  //     actions.theme.closeSubMenu(false);
  //     actions.theme.menuExpansion("");
  //   }
  // }, []);

  const [expandedMenu, setExpandedMenu] = useState("");

  const menuExpand = (name) => {
    // console.log("click!");
    expandedMenu !== name ? setExpandedMenu(name) : setExpandedMenu("closed");
    // console.log(expandedMenu);
  };

  const preventBubble = (e) => {
    e.stopPropagation();
    setHamburgerToggle(false);
  };

  const handleLogout = () => {
    setToken(false);
    localStorage.removeItem("user");
  };

  const handleColumn = (category) => {
    switch (category) {
      case "membership":
        return (
          <Column
            onClick={() => menuExpand("membership")}
            className={expandedMenu === "membership" ? "expanded" : ""}
          >
            <MenuContainer
              className={
                expandedMenu === "membership" ? "expanded bordered" : "bordered"
              }
            >
              <GrowContainer>
                <MembershipBackground className="grow" />
              </GrowContainer>
              <GradientDiv />
              <CardHeading>Membership</CardHeading>
              <MembershipItemsContainer>
                <ItemList>
                  <ItemTitle onClick={preventBubble} to="/membership">
                    Membership
                  </ItemTitle>
                  <HorizontalLine />
                  <ListItem onClick={preventBubble} to="/save-money">
                    Save Money
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/resources">
                    Resources
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/peer-groups">
                    Peer Groups
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/general-contractors">
                    General Contractors
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/specialty-contractors">
                    Specialty Contractors
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/facility-rental">
                    Facility Rental
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/membership-interest">
                    Membership Interest
                  </ListItem>
                  <br />
                  <br />
                </ItemList>
              </MembershipItemsContainer>
            </MenuContainer>
          </Column>
        );
      case "events":
        return (
          <Column
            onClick={() => menuExpand("events")}
            className={expandedMenu === "events" ? "expanded" : ""}
          >
            <MenuContainer
              className={
                expandedMenu === "events" ? "expanded bordered" : "bordered"
              }
            >
              <GrowContainer>
                <EventsBackground className="grow" />
              </GrowContainer>
              <GradientDiv />
              <CardHeading>Events</CardHeading>
              <EventsItemsContainer>
                <ItemList>
                  <ItemTitle onClick={preventBubble} to="/events">
                    Events
                  </ItemTitle>
                  <HorizontalLine />
                  <ListItem onClick={preventBubble} to="/signature-events">
                    Signature Events
                  </ListItem>
                  <br />
                  <br />
                  <ListItem
                    onClick={preventBubble}
                    to="/excellence-in-construction"
                  >
                    Excellence in Construction
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/pro-sponsorship">
                    PRO Sponsorship
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/news">
                    News
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/abc-cares">
                    ABC Cares
                  </ListItem>
                  <br />
                  <br />
                  <ListItem
                    onClick={preventBubble}
                    to="https://events.abcbaltimore.org/build-baltimore-magazine-ads/"
                  >
                    Print Advertising
                  </ListItem>
                  <br />
                  <br />
                </ItemList>
              </EventsItemsContainer>
            </MenuContainer>
          </Column>
        );
      case "management":
        return (
          <Column
            onClick={() => menuExpand("management")}
            className={expandedMenu === "management" ? "expanded large" : ""}
          >
            <MenuContainer
              className={
                expandedMenu === "management" ? "expanded bordered" : "bordered"
              }
            >
              <GradientDiv />
              <GrowContainer>
                <ManagementBackground className="grow" />
              </GrowContainer>
              <GradientDiv />
              <CardHeading>Education</CardHeading>
              <ManagementItemsContainer>
                <ItemList>
                  <ItemTitle
                    onClick={preventBubble}
                    to="/construction-education"
                  >
                    Construction <Break />
                    Education
                  </ItemTitle>
                  <HorizontalLine />
                  <ListItem onClick={preventBubble} to="/apprenticeship">
                    Apprenticeship
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/continuing-education">
                    Continuing Education
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/craft-courses">
                    Craft Courses
                  </ListItem>
                  <br />
                  <br />
                  <ListItem
                    onClick={preventBubble}
                    to="http://constructioneducationacademy.org/"
                  >
                    Daytime Trade School
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/management-education">
                    Management Education
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/technology-training">
                    Technology Training
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/pdp">
                    Professional Development
                  </ListItem>
                  <br />
                  <br />
                  <ListItem
                    onClick={preventBubble}
                    to="http://projectjumpstarttraining.org/"
                  >
                    Project JumpStart
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/customized-training">
                    Customized Training
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/electricians">
                    For Electricians Only
                  </ListItem>
                  <br />
                  <br />
                  {/* <ListItem onClick={preventBubble} to="/equipment-operator">
                    Equipment Operator Training
                  </ListItem>
                  <br />
                  <br /> */}
                </ItemList>
              </ManagementItemsContainer>
            </MenuContainer>
          </Column>
        );
      case "safety":
        return (
          <Column
            onClick={() => menuExpand("safety")}
            className={expandedMenu === "safety" ? "expanded" : ""}
          >
            <MenuContainer
              className={
                expandedMenu === "safety" ? "expanded bordered" : "bordered"
              }
            >
              <GrowContainer>
                <SafetyBackground className="grow" />
              </GrowContainer>
              <GradientDiv />
              <CardHeading>Safety</CardHeading>
              <SafetyItemsContainer>
                <ItemList>
                  <ItemTitle onClick={preventBubble} to="/safety">
                    Safety
                  </ItemTitle>
                  <HorizontalLine />
                  <ListItem onClick={preventBubble} to="/site-inspections">
                    Site Inspections
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/safety-peer-group">
                    Safety Peer Group
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/new-hire">
                    New Hire Safety Orientation
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/step">
                    STEP
                  </ListItem>
                  <br />
                  <br />
                  <ListItem
                    onClick={preventBubble}
                    to="https://events.abcbaltimore.org/events/category/safety/"
                  >
                    Safety Training
                  </ListItem>
                  <br />
                  <br />
                </ItemList>
              </SafetyItemsContainer>
            </MenuContainer>
          </Column>
        );
      case "political":
        return (
          <Column
            onClick={() => menuExpand("political")}
            className={expandedMenu === "political" ? "expanded" : ""}
          >
            <MenuContainer
              className={
                expandedMenu === "political" ? "expanded bordered" : "bordered"
              }
            >
              <GrowContainer>
                <PoliticalBackground className="grow" />
              </GrowContainer>
              <GradientDiv />
              <CardHeading>
                Political <MobileBreak />
                Advocacy
              </CardHeading>
              <PoliticalItemsContainer>
                <ItemList>
                  <ItemTitle onClick={preventBubble} to="/political-advocacy">
                    Political
                    <Break /> Advocacy
                  </ItemTitle>
                  <HorizontalLine />
                  <ListItem
                    onClick={preventBubble}
                    to="https://www.abc.org/Politics-Policy/ABC-PAC"
                  >
                    National PAC
                  </ListItem>
                  <br />
                  <br />
                  <ListItem
                    onClick={preventBubble}
                    to="https://www.webuildmaryland.org"
                  >
                    We Build Maryland
                  </ListItem>
                  <br />
                  <br />
                  <ListItem
                    onClick={preventBubble}
                    to="https://www.abc.org/Politics-Policy/ABC-Action-App/Action-Center"
                  >
                    ABC Action Center
                  </ListItem>
                  <br />
                  <br />
                </ItemList>
              </PoliticalItemsContainer>
            </MenuContainer>
          </Column>
        );
      case "members":
        return (
          <Column
            onClick={() => menuExpand("members")}
            className={expandedMenu === "members" ? "expanded" : ""}
          >
            <MenuContainer
              className={expandedMenu === "members" ? "expanded" : ""}
            >
              <GrowContainer>
                <MembersBackground className="grow" />
              </GrowContainer>
              <GradientDiv />
              <CardHeading>Members Only</CardHeading>
              <MembersItemsContainer>
                <ItemList>
                  <ItemTitle onClick={preventBubble} to="/members">
                    Members
                    <Break /> Only
                  </ItemTitle>
                  <HorizontalLine />
                  <ListItem onClick={preventBubble} to="/virtual-hr">
                    Virtual HR
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/find-a-contractor">
                    Find a Contractor
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/personal-coaching">
                    Personal Business Coaching
                  </ListItem>
                  <br />
                  <br />
                  <ListItem
                    onClick={preventBubble}
                    to="https://secure.abcbaltimore.org/products/invoice"
                  >
                    Pay an Invoice
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/other-resources">
                    Other Member Resource
                  </ListItem>
                  <br />
                  <br />
                  <ListItem onClick={preventBubble} to="/about-us">
                    About Us
                  </ListItem>
                  <br />
                  <br />
                  {token ? (
                    <ListItem to="#" onClick={() => handleLogout()}>
                      Logout
                    </ListItem>
                  ) : (
                    <ListItem onClick={preventBubble} to="/login">
                      Member Login
                    </ListItem>
                  )}
                </ItemList>
              </MembersItemsContainer>
            </MenuContainer>
          </Column>
        );
      default:
        return <></>;
    }
  };

  return (
    <Wrapper>
      <GridWrapper>
        {handleColumn("membership")}
        {handleColumn("events")}
        {handleColumn("management")}
        {handleColumn("safety")}
        {handleColumn("political")}
        {handleColumn("members")}
      </GridWrapper>
    </Wrapper>
  );
};

export default MobileMenu;
