import React, { useState, useEffect } from "react";
import CsvDownload from "react-json-to-csv";

import convertJSONtoXLSX from "../../utils/jsontoxlsx";

import Page from "../../components/page/page.component";
import SearchBar from "../../components/searchbar/searchbar.component.jsx";
import Login from "../../components/login/login.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import { MemberList } from "../../components/memberlist/memberlist.component.jsx";
import {
  Wrapper,
  InnerWrapper,
  LoggedOutWrapper,
  SearchContainer,
} from "./searchpage.styles";

let collectionMap = require("../../static/2023_Directory_Listings_JSON.json");

const SearchPage = ({ data, token, setToken }) => {
  const [input, setInput] = useState("");
  const [memberListDefault, setMemberListDefault] = useState();
  const [memberList, setMemberList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [mWBECheck, setmWBECheck] = useState(false);
  const [downloadList, setDownloadList] = useState([]);
  // const collectionRef = firestore.collection('members').orderBy("Company");

  const toggleMWBE = () => {
    setmWBECheck(!mWBECheck);
  };

  const setPage = (currentPage) => {
    setPageNumber(currentPage);
  };

  useEffect(() => {
    setMemberList(collectionMap);
    setMemberListDefault(collectionMap);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setToken(loggedInUser);
    }
  }, []);

  useEffect(() => {
    const updateView = (list) => {
      setFilteredList(
        list.filter(
          (item, idx) =>
            (pageNumber - 1) * 10 <= idx && idx <= (pageNumber - 1) * 10 + 9
        )
      );
    };
    if (mWBECheck) {
      if (!input) {
        populateDownload(memberList);
      }
      updateView(memberList.filter((item) => item.mWBE === "M/WBE"));
    } else {
      updateView(memberList);
    }
  }, [pageNumber, memberList, mWBECheck]);

  const populateDownload = (list) => {
    const filteredDownloadList = list.map((item) => ({
      Company: item.Company,
      Website: item.Website,
      Phone: item.Phone,
      Contact: item.Name,
      Contact_Email: item.Email,
      Address: item["Mail-Address1"],
      City: item["Mail-City"],
      State: item["Mail-State"],
      Zipcode: item["Mail-Zip"],
      mWBE: item.mWBE,
    }));
    if (mWBECheck) {
      setDownloadList(
        filteredDownloadList.filter((item) => item.mWBE === "TRUE")
      );
    } else {
      setDownloadList(filteredDownloadList);
    }
  };

  const updateInput = async (input) => {
    setPageNumber(1);
    const filtered = memberListDefault.filter((member) => {
      const {
        Company,
        ["TradeCode 1"]: TradeCode1,
        ["TradeCode 2"]: TradeCode2,
        ["TradeCode 3"]: TradeCode3,
        ["TradeCode 4"]: TradeCode4,
        ["TradeCode 5"]: TradeCode5,
        ["TradeCode 6"]: TradeCode6,
        ["TradeCode 8"]: TradeCode8,
        ["TradeCode 9"]: TradeCode9,
        ["TradeCode 10"]: TradeCode10,
        ["TradeCode 11"]: TradeCode11,
        ["TradeCode 12"]: TradeCode12,
        ["TradeCode 13"]: TradeCode13,
        ["TradeCode 14"]: TradeCode14,
        ["TradeCode 15"]: TradeCode15,
        ["TradeCode 16"]: TradeCode16,
        ["TradeCode 17"]: TradeCode17,
        ["TradeCode 18"]: TradeCode18,
        ["TradeCode 19"]: TradeCode19,
        ["TradeCode 20"]: TradeCode20,
        ["TradeCode 21"]: TradeCode21,
        ["TradeCode 22"]: TradeCode22,
        ["TradeDesc 1"]: TradeDesc1,
        ["TradeDesc 2"]: TradeDesc2,
        ["TradeDesc 3"]: TradeDesc3,
        ["TradeDesc 4"]: TradeDesc4,
        ["TradeDesc 5"]: TradeDesc5,
        ["TradeDesc 6"]: TradeDesc6,
        ["TradeDesc 8"]: TradeDesc8,
        ["TradeDesc 9"]: TradeDesc9,
        ["TradeDesc 10"]: TradeDesc10,
        ["TradeDesc 11"]: TradeDesc11,
        ["TradeDesc 12"]: TradeDesc12,
        ["TradeDesc 13"]: TradeDesc13,
        ["TradeDesc 14"]: TradeDesc14,
        ["TradeDesc 15"]: TradeDesc15,
        ["TradeDesc 16"]: TradeDesc16,
        ["TradeDesc 17"]: TradeDesc17,
        ["TradeDesc 18"]: TradeDesc18,
        ["TradeDesc 19"]: TradeDesc19,
        ["TradeDesc 20"]: TradeDesc20,
        ["TradeDesc 21"]: TradeDesc21,
        ["TradeDesc 22"]: TradeDesc22,
        ["TradeDesc 23"]: TradeDesc23,
        ["TradeDesc 24"]: TradeDesc24,
        mWBE,
        ...partialObject
      } = member;
      const subset = {
        Company,
        TradeCode1,
        TradeCode2,
        TradeCode3,
        TradeCode4,
        TradeCode5,
        TradeCode6,
        TradeCode8,
        TradeCode9,
        TradeCode10,
        TradeCode11,
        TradeCode12,
        TradeCode13,
        TradeCode14,
        TradeCode15,
        TradeCode16,
        TradeCode17,
        TradeCode18,
        TradeCode19,
        TradeCode20,
        TradeCode21,
        TradeCode22,
        TradeDesc1,
        TradeDesc2,
        TradeDesc3,
        TradeDesc4,
        TradeDesc5,
        TradeDesc6,
        TradeDesc8,
        TradeDesc9,
        TradeDesc10,
        TradeDesc11,
        TradeDesc12,
        TradeDesc13,
        TradeDesc14,
        TradeDesc15,
        TradeDesc16,
        TradeDesc17,
        TradeDesc18,
        TradeDesc19,
        TradeDesc20,
        TradeDesc21,
        TradeDesc22,
        TradeDesc23,
        TradeDesc24,
        mWBE,
      };
      const values = Object.values(subset);
      const includesMultiDimension = (arr, str) =>
        JSON.stringify(arr).toLowerCase().includes(str.toLowerCase());
      return includesMultiDimension(values, input);
    });
    setInput(input);
    setMemberList(filtered);
    populateDownload(filtered);
  };

  return (
    <Wrapper>
      <Page data={data} />
      <SearchContainer>
        <label htmlFor="mWBE">
          <input type="checkbox" id="mWBE" onChange={toggleMWBE} />
          M/WBE
        </label>
        <CsvDownload data={downloadList}>Download CSV File</CsvDownload>
        <CustomButton onClick={() => convertJSONtoXLSX(downloadList)}>
          Download Excel File
        </CustomButton>
        <p className="white-text">
          *Data only available for download after search. This will provide you
          a .csv file, which will need to be imported into either excel or
          google sheets.
          <br />
          <a
            target="_blank"
            href="https://coefficient.io/import-csv-into-google-sheets#:~:text=You%20can%20also%20import%20CSVs%20into%20Google%20Sheets%20directly%20from,upload%20one%20from%20your%20device."
          >
            Google Sheets Instructions
          </a>
          <br />
          <a
            target="_blank"
            href="https://www.copytrans.net/support/how-to-open-a-csv-file-in-excel/"
          >
            Excel Instructions
          </a>
        </p>
        <br />
        <SearchBar
          input={input}
          onChange={updateInput}
          pageNumber={pageNumber}
          onClick={setPage}
          data={filteredList.length}
          total={mWBECheck ? downloadList.length : memberList.length}
        />
        <MemberList memberList={filteredList} />
        <br />
        <SearchBar
          noSearch
          input={input}
          onChange={updateInput}
          pageNumber={pageNumber}
          onClick={setPage}
          data={filteredList.length}
          total={memberList.length}
        />
      </SearchContainer>
      {token ? null : (
        <LoggedOutWrapper>
          <InnerWrapper>
            <Login setToken={setToken} />
          </InnerWrapper>
        </LoggedOutWrapper>
      )}
    </Wrapper>
  );
};

export default SearchPage;
