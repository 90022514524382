import React from "react";

import { Link } from "react-router-dom";

import { TitleBlock, AccentLineMain, FooterBlock } from "../../App.styles";

import CustomButton from "../../components/custom-button/custom-button.component";
import SocialIcons from "../../components/social-icons/social-icons.component";

import {
  Wrapper,
  ThreeColumn,
  Column,
  ColumnImage,
} from "./prosponsorpage.styles";

const ProSponsorPage = ({}) => {
  return (
    <>
      <Wrapper>
        <TitleBlock>
          <div>
            <h1 className="newSiteHeading">Pro Sponsorship</h1>
          </div>
          <div>
            <h3>
              Connect your brand to the largest construction business network in
              Maryland, with Premier Recognition Opportunities (PRO)
            </h3>
            <br />
            <p>
              As the largest construction network in Maryland ABC can connect
              your brand to all the important decision makers through a package
              of print, online and face-2-face marketing opportunities.
            </p>
            <Link to="https://dev.abcbaltimore.org/wp-content/uploads/2024/08/PRO-SPONSORSHIPS-2024_UPDATE.pdf">
              <CustomButton newSiteButton centered>
                Download the Sponsorship Guide
              </CustomButton>
            </Link>
            <SocialIcons />
          </div>
        </TitleBlock>
        <AccentLineMain />
        <ThreeColumn>
          <Column>
            <ColumnImage
              className="contain"
              src="https://dev.abcbaltimore.org/wp-content/uploads/2023/08/bbm_cover.webp"
              alt="Build Baltomore Magazine Cover"
            />
            <br />
            <h3>
              <a
                target="_blank"
                href="https://events.abcbaltimore.org/build-baltimore-magazine-ads/"
              >
                Print Marketing
              </a>
            </h3>
            <p>
              Through ABC's bi-monthly four-color magazine, Building Baltimore
              and our annual Membership Directory - THE most widely distributed
              directory of construction firms in the region.
            </p>
          </Column>
          <Column>
            <ColumnImage
              src="https://dev.abcbaltimore.org/wp-content/uploads/2021/12/15110491_10154639932488604_6482511836676231861_o-1024x683.jpg"
              alt="State of the Industry Event with Anirban Basu"
            />
            <br />
            <h3>
              <Link to="/events">Face-2-Face</Link>
            </h3>
            <p>
              Each month ABC offers a variety of opportunities for you to expand
              your industry network.
            </p>
          </Column>
          <Column>
            <ColumnImage
              src="https://dev.abcbaltimore.org/wp-content/uploads/2022/02/Screen-Shot-2022-02-04-at-7.58.07-AM-300x230.png"
              alt="ABC's Facebook Page"
            />
            <br />
            <h3>
              <a
                target="_blank"
                href="https://events.abcbaltimore.org/build-baltimore-magazine-ads/"
              >
                Social
              </a>
            </h3>
            <p>
              Tap into the power of the largest construction social media
              network in the region!
            </p>
          </Column>
        </ThreeColumn>
        <FooterBlock>
          <h2 className="newSiteHeading">
            <a
              href="https://events.abcbaltimore.org/pro-sponsorships/"
              target="_blank"
            >
              Get Started with Your Pro Sponsorship
            </a>
          </h2>
          <h3 className="white-text">
            Contact{" "}
            <a target="_blank" href="mailto:bchaapel@abcbaltimore.org">
              Brittany Chaapel
            </a>
          </h3>
        </FooterBlock>
        <br />
        <h3>ABC Greater Baltimore's Pro Sponsors</h3>
        <img
          src="https://dev.abcbaltimore.org/wp-content/uploads/2023/12/Pro_Sponsor_Grid_12_23-757x1024.png"
          alt="ABC's current list of pro sponsor logos"
        />
      </Wrapper>
    </>
  );
};

export default ProSponsorPage;
