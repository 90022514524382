import React from "react";

import { myunescape } from "../../utils/utility-functions";

import { BlogLink, BlogTile, BlogTitleContainer } from "./blogPostThumb.styles";

const CaresPostThumb = ({ item }) => {
  return (
    <BlogLink key={item.id} to={`abc-cares/${item.id}`}>
      <BlogTile>
        <div>
          {item.featured_image_urls_v2 ? (
            <img src={item.featured_image_urls_v2.large[0]} alt="" />
          ) : null}
        </div>
        <BlogTitleContainer>
          {myunescape(item.title.rendered)}
        </BlogTitleContainer>
      </BlogTile>
    </BlogLink>
  );
};

export default CaresPostThumb;
