import React from "react";
import { Background, BackgroundOverlay } from "./page-background.styles";

const PageBackground = ({ ...props }) => {
  return (
    <>
      <Background {...props} />
      {/* <BackgroundOverlay /> */}
    </>
  );
};

export default PageBackground;
