import React, { useEffect, useRef, useState, useMemo } from "react";

import { Link } from "react-router-dom";

import LogoContainer from "../../components/logo-container/logo-container.component";
import BlogPostThumb from "../../components/blogPostThumb/blogPostThumb.component";
import CaresPostThumb from "../../components/blogPostThumb/caresPostThumb.component";
import YouTubePostThumb from "../../components/youTubePostThumb/youTubePostThumb.component";
import Spinner from "../spinner/spinner.component";

import { menu } from "../../components/side-menu/menuFile";
import { youTubeFetch } from "../../utils/events-fetch";

import logoImage from "../../static/images/abc_logo.svg";
import calIcon from "../../static/images/cal_icon.svg";

import {
  FeaturedEventContainer,
  FeaturedImage,
  Grid,
  GridCell,
  HamburgerIcon,
  HeaderButton,
  HeaderTopBar,
  IconContainer,
  IconImage,
  LinkBar,
  LinkBarLink,
  MagazineTile,
  MediaTitle,
  MenuContainer,
  MenuWrapper,
  MenuItems,
  MenuLink,
  RightLinks,
  TitleBar,
} from "./header2.styles";

const Header2 = ({
  blogPosts,
  // youTubePosts,
  // setYouTubePosts,
  caresPosts,
  eventsCalendar,
  hamburgerToggle,
  setHamburgerToggle,
  setToken,
  token,
  intViewportWidth,
}) => {
  const videoRef = useRef(null);
  const [menuToggle, setMenuToggle] = useState(false);
  const [menuName, setMenuName] = useState("");
  const [youTubePosts, setYouTubePosts] = useState(null);
  const [count, setCount] = useState(0);

  // console.log("CARES POSTS", caresPosts);

  const mappedEventList = () => {
    const list = eventsCalendar.map((items) => {
      if (items.image) {
        return (
          <div key={`${Math.random()} ${items.id}`}>
            <FeaturedImage>
              <a target="_blank" href={items.url}>
                <img src={items.image.url} />
              </a>
            </FeaturedImage>
          </div>
        );
      }
    });
    // console.log("list", list);
    return list.filter((el) => el !== undefined).slice(0, 1);
  };

  const MenuContent = ({ type }) => {
    switch (type) {
      case "training":
        return trainingMenu;
      case "membership":
        return membershipMenu;
      case "media":
        return mediaMenu;
      case "events":
        return eventsMenu;
    }
  };

  useEffect(() => {
    const playVideo = videoRef.current;
    if (playVideo) {
      playVideo.play();
    }
  }, []);

  useEffect(() => {
    if (youTubePosts !== null) {
      // console.log("no fetch needed");
    } else {
      console.log("fetching youtube");
      try {
        // throw new Error("HI");
        youTubeFetch().then((res) => {
          if (!res.error) {
            setYouTubePosts(res);
          }
          console.log("YOUTUBE RESULTS", res);
        });
      } catch (error) {
        setYouTubePosts(null);
      }
    }
  }, []);

  useEffect(() => {
    console.log("FETCHED");
  }, [blogPosts, caresPosts]);

  const toggleMenu = (toggleName) => {
    setMenuName(toggleName);
    setMenuToggle(true);
  };

  const closeMenu = () => {
    setMenuName(null);
    setMenuToggle(false);
  };

  const handleLogout = () => {
    setToken(false);
    localStorage.removeItem("user");
  };

  const handleHamburger = () => {
    setHamburgerToggle(!hamburgerToggle);
  };

  const trainingMenu = useMemo(() => {
    return (
      <MenuContainer onMouseLeave={closeMenu}>
        <TitleBar>
          <MediaTitle>
            <Link to="/construction-education">EDUCATION</Link>
          </MediaTitle>
          <MediaTitle>
            <Link to="/safety">SAFETY</Link>
          </MediaTitle>
        </TitleBar>
        <Grid columns={2}>
          <MenuItems className="blue flex" gridColumn={"1/2"}>
            <div>
              {Object.entries(menu.mainMenu.education.submenu)
                .slice(0, 6)
                .map((item) => {
                  return (
                    // <MenuItemContainer key={item[1].id}>
                    <MenuLink
                      key={`${Math.random()} ${item.id}`}
                      to={item[1].link}
                    >
                      <p>{item[1].menuName}</p>
                    </MenuLink>
                    // </MenuItemContainer>
                  );
                })}
              <br />
            </div>
            <div>
              {Object.entries(menu.mainMenu.education.submenu)
                .slice(6)
                .map((item) => {
                  return (
                    // <MenuItemContainer key={item[1].id}>
                    <MenuLink
                      key={`${Math.random()} ${item.id}`}
                      to={item[1].link}
                    >
                      <p>{item[1].menuName}</p>
                    </MenuLink>
                    // </MenuItemContainer>
                  );
                })}
            </div>
          </MenuItems>
          <MenuItems gridColumn={"2/-1"}>
            {Object.entries(menu.mainMenu.safety.submenu).map((item) => {
              return (
                // <MenuItemContainer key={item[1].id}>
                <MenuLink key={`${Math.random()} ${item.id}`} to={item[1].link}>
                  <p>{item[1].menuName}</p>
                </MenuLink>
                // </MenuItemContainer>
              );
            })}
          </MenuItems>
        </Grid>
      </MenuContainer>
    );
  }, [menu]);

  const membershipMenu = useMemo(() => {
    return (
      <MenuContainer onMouseLeave={closeMenu}>
        <TitleBar>
          <MediaTitle>
            <Link to="/membership">MEMBERSHIP</Link>
          </MediaTitle>
          <MediaTitle>
            <Link to="/members">MEMBERS ONLY</Link>
          </MediaTitle>
          <MediaTitle>
            <Link to="/political-advocacy">POLITICAL ADVOCACY</Link>
          </MediaTitle>
        </TitleBar>
        <Grid columns={3}>
          <MenuItems className="flex" gridColumn={"1/2"}>
            <div>
              {Object.entries(menu.mainMenu.membership.submenu)
                .slice(0, 4)
                .map((item) => {
                  return (
                    // <MenuItemContainer key={item[1].id}>
                    <MenuLink
                      key={`${Math.random()} ${item.id}`}
                      to={item[1].link}
                    >
                      <p>{item[1].menuName}</p>
                    </MenuLink>
                    // </MenuItemContainer>
                  );
                })}
              <br />
            </div>
            <div>
              {Object.entries(menu.mainMenu.membership.submenu)
                .slice(4)
                .map((item) => {
                  return (
                    // <MenuItemContainer key={item[1].id}>
                    <MenuLink
                      key={`${Math.random()} ${item.id}`}
                      to={item[1].link}
                    >
                      <p>{item[1].menuName}</p>
                    </MenuLink>
                    // </MenuItemContainer>
                  );
                })}
            </div>
            {/* <span>&nbsp;</span> */}
          </MenuItems>
          <MenuItems className="flex" gridColumn={"2/3"}>
            <div>
              {Object.entries(menu.memberMenu.members.submenu)
                .slice(0, 4)
                .map((item) => {
                  return (
                    // <MenuItemContainer key={item[1].id}>
                    <MenuLink
                      key={`${Math.random()} ${item.id}`}
                      to={item[1].link}
                    >
                      <p>{item[1].menuName}</p>
                    </MenuLink>
                    // </MenuItemContainer>
                  );
                })}
            </div>
            <div>
              {Object.entries(menu.memberMenu.members.submenu)
                .slice(4)
                .map((item) => {
                  return (
                    // <MenuItemContainer key={item[1].id}>
                    <MenuLink
                      key={`${Math.random()} ${item.id}`}
                      to={item[1].link}
                    >
                      <p>{item[1].menuName}</p>
                    </MenuLink>
                    // </MenuItemContainer>
                  );
                })}
            </div>
          </MenuItems>
          <MenuItems className="flex" gridColumn={"3/-1"}>
            <div>
              {Object.entries(menu.mainMenu.politicalAdvocacy.submenu).map(
                (item) => {
                  return (
                    // <MenuItemContainer key={item[1].id}>
                    <MenuLink
                      key={`${Math.random()} ${item.id}`}
                      to={item[1].link}
                    >
                      <p>{item[1].menuName}</p>
                    </MenuLink>
                    // </MenuItemContainer>
                  );
                }
              )}
            </div>
          </MenuItems>
        </Grid>
      </MenuContainer>
    );
  }, [menu]);

  const mediaMenu = useMemo(() => {
    return (
      <MenuContainer onMouseLeave={closeMenu} className="quad">
        <TitleBar>
          <MediaTitle className="quad">
            <Link to="/news">NEWS</Link>
          </MediaTitle>
          <MediaTitle className="quad">
            <Link to="https://mydigitalpublication.com/publication/?m=14697&l=1&p=1&view=issuelistBrowser&ver=html5">
              MAGAZINE
            </Link>
          </MediaTitle>
          <MediaTitle className="quad">
            <Link to="https://www.youtube.com/channel/UC1HN8StFmyDwnMN3Qzk6X8A/videos">
              VIDEOS
            </Link>
          </MediaTitle>
          <MediaTitle className="quad">
            <Link to="/abc-cares">ABC CARES</Link>
          </MediaTitle>
        </TitleBar>
        <Grid columns={4} className="quad">
          {/* blog posts  */}
          <GridCell>
            {blogPosts && blogPosts.length > 0 ? (
              blogPosts.slice(0, 2).map((items) => {
                return (
                  <BlogPostThumb
                    key={`${Math.random()} ${items.id}`}
                    item={items}
                  />
                );
              })
            ) : (
              <Spinner />
            )}
          </GridCell>
          {/* magazine  */}
          <div>
            <a
              target="_blank"
              href="https://mydigitalpublication.com/publication/?m=14697&l=1&view=issuelistBrowser"
            >
              <MagazineTile>
                <img src="https://cdn.coverstand.com/14697/828565/iphonejpg/320/6eec0dd5e2973e54c8f62b6b92484497f6aa51a6-00001.jpg" />
              </MagazineTile>
            </a>
          </div>
          {/* youtube  */}
          <GridCell>
            {youTubePosts && youTubePosts !== null ? (
              youTubePosts.items.slice(0, 2).map((items) => {
                return (
                  <YouTubePostThumb
                    key={`${Math.random()} ${items.id}`}
                    item={items}
                  />
                );
              })
            ) : (
              <h4>YouTube currently Unavailable</h4>
            )}
          </GridCell>
          {/* ABC cares  */}
          <GridCell>
            {caresPosts && caresPosts.length > 0 ? (
              caresPosts.slice(0, 2).map((items) => {
                return (
                  <CaresPostThumb
                    key={`${Math.random()} ${items.id}`}
                    item={items}
                  />
                );
              })
            ) : (
              <Spinner />
            )}
          </GridCell>
        </Grid>
      </MenuContainer>
    );
  }, [menu, eventsCalendar, youTubePosts, blogPosts, caresPosts]);

  const eventsMenu = useMemo(() => {
    return (
      <MenuContainer onMouseLeave={closeMenu}>
        <TitleBar>
          <MediaTitle>
            <Link to="/events">EVENTS</Link>
          </MediaTitle>
        </TitleBar>
        <Grid columns={3}>
          <MenuItems className="blue" gridColumn={"1/2"}>
            {Object.entries(menu.mainMenu.events.submenu).map((item) => {
              return (
                // <MenuItemContainer key={item[1].id}>
                <MenuLink key={`${Math.random()} ${item.id}`} to={item[1].link}>
                  <p>{item[1].menuName}</p>
                </MenuLink>
                // </MenuItemContainer>
              );
            })}
            {/* <span>&nbsp;</span> */}
          </MenuItems>
          <FeaturedEventContainer>{mappedEventList()}</FeaturedEventContainer>
        </Grid>
      </MenuContainer>
    );
  }, [menu, eventsCalendar]);

  return (
    <>
      {/* megamenu */}
      <HeaderTopBar>
        {intViewportWidth < 1100 ? (
          <>
            {window.location.pathname !== "/" ? (
              <HamburgerIcon onClick={() => handleHamburger()}>
                &#9776;
              </HamburgerIcon>
            ) : null}
            <LogoContainer
              link="/"
              source={logoImage}
              widthValue="150px"
              heightValue="auto"
            />
          </>
        ) : (
          <LogoContainer
            link="/"
            source={logoImage}
            widthValue="300px"
            heightValue="auto"
          />
        )}
        <div style={{ textAlign: "right" }}>
          {intViewportWidth > 1100 ? (
            <Link to="membership-interest">
              <HeaderButton>Join ABC</HeaderButton> <br />
            </Link>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link style={{ fontWeight: 600 }} to="/about">
                ABOUT US
              </Link>
              <Link to="/events">
                <IconContainer>
                  <IconImage src={calIcon} alt="ABC Events Calendar" />
                </IconContainer>
              </Link>
            </div>
          )}
          {intViewportWidth < 1100 ? null : (
            <>
              <Link to="/find-a-contractor">Find a Contractor</Link> <br />
              {token ? (
                <Link to="" onClick={handleLogout}>
                  Logout
                </Link>
              ) : (
                <Link to="/login">Member Login</Link>
              )}
            </>
          )}
        </div>
      </HeaderTopBar>
      <LinkBar onMouseLeave={closeMenu}>
        <RightLinks>
          <LinkBarLink
            onMouseEnter={() => toggleMenu("training")}
            //   onMouseLeave={closeMenu}
            onFocus={() => toggleMenu("training")}
            onBlur={closeMenu}
            to="/construction-education"
          >
            TRAINING
          </LinkBarLink>
          <LinkBarLink
            onMouseEnter={() => toggleMenu("membership")}
            //   onMouseLeave={closeMenu}
            onFocus={() => toggleMenu("membership")}
            onBlur={closeMenu}
            to="/membership"
          >
            MEMBERSHIP
          </LinkBarLink>
          <LinkBarLink
            onMouseEnter={() => toggleMenu("media")}
            //   onMouseLeave={closeMenu}
            onFocus={() => toggleMenu("media")}
            onBlur={closeMenu}
            to="/news"
          >
            MEDIA
          </LinkBarLink>
          <LinkBarLink
            onMouseEnter={() => toggleMenu("events")}
            //   onMouseLeave={closeMenu}
            onFocus={() => toggleMenu("events")}
            onBlur={closeMenu}
            to="/events"
          >
            EVENTS
          </LinkBarLink>
          <LinkBarLink onMouseEnter={closeMenu} to="/about-us">
            ABOUT US
          </LinkBarLink>
          <Link to="/events">
            <IconContainer>
              <IconImage src={calIcon} alt="ABC Events Calendar" />
            </IconContainer>
          </Link>
        </RightLinks>
        <MenuWrapper>
          {menuToggle ? <MenuContent type={menuName} /> : null}
        </MenuWrapper>
      </LinkBar>
    </>
  );
};

export default React.memo(Header2);
