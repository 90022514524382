import React from "react";

import LogoContainer from "../logo-container/logo-container.component";

import facebookLogo from "../../static/images/Facebook.png";
import youtubeLogo from "../../static/images/Youtube.png";
import linkedinLogo from "../../static/images/LinkedIn.png";
import instagramLogo from "../../static/images/Instagram.png";
import twitterLogo from "../../static/images/Twitter.png";

import {
  SocialRow,
  Facebook,
  Youtube,
  Twitter,
  Instagram,
  LinkedIn,
} from "./social-icons.styles";

const SocialIcons = ({}) => {
  return (
    <SocialRow>
      <Facebook>
        <LogoContainer
          margin="0px"
          source={facebookLogo}
          alt="Facebook Logo"
          widthValue="30px"
          heightValue="30px"
          link="http://www.facebook.com/abcgreaterbaltimore"
          target="_blank"
        />
      </Facebook>
      <Youtube>
        <LogoContainer
          margin="0px"
          source={youtubeLogo}
          alt="Youtube Logo"
          widthValue="30px"
          heightValue="30px"
          link="https://www.youtube.com/channel/UC1HN8StFmyDwnMN3Qzk6X8A/videos"
        />
      </Youtube>
      <Twitter>
        <LogoContainer
          margin="0px"
          source={twitterLogo}
          alt="Twitter Logo"
          widthValue="30px"
          heightValue="30px"
          link="http://www.twitter.com/abcbaltimore"
        />
      </Twitter>
      <Instagram>
        <LogoContainer
          margin="0px"
          source={instagramLogo}
          alt="Instagram Logo"
          widthValue="30px"
          heightValue="30px"
          link="https://www.instagram.com/abcgreaterbaltimore/"
        />
      </Instagram>
      <LinkedIn>
        <LogoContainer
          margin="0px"
          source={linkedinLogo}
          alt="LinkedIn Logo"
          widthValue="30px"
          heightValue="30px"
          link="https://www.linkedin.com/company/abcgreaterbaltimore "
        />
      </LinkedIn>
    </SocialRow>
  );
};

export default SocialIcons;
