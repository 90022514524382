//Window Resize Listener in useCurrentWidth is not firing

import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router";
import { useLocation, Navigate } from "react-router-dom";
import { Global, css } from "@emotion/react";
import { Helmet } from "react-helmet";

import { useTransition, animated } from "@react-spring/web";
// import { data } from "./test_data.js";

import {
  GlobalStyle,
  Parent,
  CalendarContainer,
  NewsContainer,
  HomepageContainer,
  MainTag,
  AnimationWrapper,
  SpacingDiv,
  ContentWrapper,
} from "./App.styles.js";
import gutenburgStyle from "./styles/gutenburg/style.css";
import gutenburgTheme from "./styles/gutenburg/theme.css";
import adminWelcome from "./styles/stackable/admin_welcome.css";
import editorBlocks from "./styles/stackable/editor_blocks_wp_v5_3.css";
import editorBlocksTwo from "./styles/stackable/editor_blocks.css";
import frontend_blocks from "./styles/stackable/frontend_blocks.css";

import {
  eventsFetch,
  blogFetch,
  caresFetch,
  dataFetch,
} from "./utils/events-fetch.js";

import EICPage from "./Pages/eicpage/eicpage.jsx";
import EventsCalendar from "./components/events-calendar/events-calendar.component";
import LoginPage from "./Pages/loginpage/loginpage";
import HomePage from "./Pages/homepage/homepage";
import SpanishSpeakingPage from "./Pages/spanishspeakingpage/spanishspeakingpage.jsx";
import HomePage2 from "./Pages/homepage/homepage2";
import EventsPage from "./Pages/eventspage/eventspage";
import EventsPublishPage from "./Pages/eventspublishpage/eventspublishpage.jsx";
import PdpPage from "./Pages/pdppage/pdppage";
import SearchPage from "./Pages/searchpage/searchpage";
import InvoicePage from "./Pages/invoicepage/invoicepage";
import AboutUsPage from "./Pages/aboutuspage/aboutuspage";
import LogicScholarshipPage from "./Pages/logicscholarshippage/logicscholarshippage";
import ApprenticeAppPage from "./Pages/apprenticeapppage/apprenticeapppage";
import ApprenticeResumePage from "./Pages/apprenticeresumepage/apprenticeresumepage";
import LogicInterestPage from "./Pages/logicinterestpage/logicinterestpage.jsx";
import ResumePrint from "./Pages/apprenticeresumeprintpage/apprenticeresumeprintpage";
import JoinABCPage from "./Pages/joinABCpage/joinABCpage";
import VirtualHRPage from "./Pages/virtualhrpage/virtualhrpage";
import BenefitBookPage from "./Pages/benefitbookpage/benefitbookpage";
import ProSponsorPage from "./Pages/prosponsorpage/prosponsorpage";
import TaskTrainingPage from "./Pages/tasktrainingpage/tasktrainingpage";
import FacilityRentalPage from "./Pages/facilityrentalpage/facilityrentalpage";
import CompanyUpdatePage from "./Pages/companyupdatepage/companyupdatepage";
import SchoolDataPage from "./Pages/schooldatapage/schooldatapage";
import RegistrationPage from "./Pages/registrationpage/registrationpage";
import ABCCaresPage from "./Pages/abccarespage/abccarespage";
import BlogPage from "./Pages/blogpage/blogpage.jsx";
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import NewsBar from "./components/news-bar/news-bar.component";
import SideMenu from "./components/side-menu/side-menu.component";
import Page from "./components/page/page.component";
import PageBackground from "./components/page-background/page-background.component";
import CaresBlog from "./components/blog/caresBlog.component";
import MobileMenu from "./components/mobile-menu/mobile-menu.component.jsx";
import SaveMoneyPage from "./Pages/saveMoneyPage/saveMoneyPage.jsx";
import SignatureEventsPage from "./Pages/signatureeventspage/signatureeventspage.jsx";

import Blog from "./components/blog/blog.component";

import { useCurrentWidth } from "./utils/utility-functions";
import Header2 from "./components/header/header2.component.jsx";
import AcademiesPage from "./Pages/academiespage/academiespage.jsx";

function InvoiceRedirect() {
  window.location.replace("https://secure.abcbaltimore.org/products/invoice");
}

function App() {
  let location = useLocation();
  const { pathname } = useLocation();

  const [data, setData] = useState({});
  const [expandedMenu, setExpandedMenu] = useState("");
  const [hamburgerToggle, setHamburgerToggle] = useState(false);
  const [token, setToken] = useState(null);
  // const [youTubePosts, setYouTubePosts] = useState(null);
  const [blogPosts, setBlogPosts] = useState({});
  const [caresPosts, setCaresPosts] = useState({});
  const [newsToggle, setNewsToggle] = useState(false);
  const [calendarToggle, setCalendarToggle] = useState(false);
  const [eventsCalendar, setEventsCalendar] = useState([]);
  const [intViewportWidth, setIntViewportWidth] = useState(useCurrentWidth());
  const [loginVisible, setLoginVisible] = useState(false);
  const [isVisible, setIsVisible] = useState("visible");
  const [resumeData, setResumeData] = useState({});
  const [newsItem, setNewsItem] = useState("blog");

  const styleIDs = {
    membership: [198, 991, 221, 1133, 1312, 1314, 1451, 435, 2601, 2950, 3075],
    apprenticeship: [],
    events: [208, 1290],
    safety: [210, 212, 187, 566, 2065],
    management: [
      1303, 1299, 1297, 1301, 1295, 1544, 1546, 1635, 1633, 1656, 1668, 1640,
      2156, 1100, 206, 207, 217, 1255, 1393, 1635, 1661, 2316, 2133, 2791, 2896,
      2391, 2453, 2654, 2918, 3125,
    ],
    political: [204, 1305, 1209, 1307, 1206],
    members: [1741, 222, 932, 199, 2125, 2275, 2306, 2436, 2785],
  };

  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      height: "100%",
      // overflowY: "scroll",
      // overflowX: "hidden",
    },
    leave: { opacity: 0, display: "none" },
    config: { duration: 500 },
    delay: 500,
  });

  const path = window.location.pathname;

  const page = Object.entries(data).filter((el) => {
    return path.includes(el[1].slug);
  });

  const backgroundStyle = () => {
    const style = page[0][1].id;
    const type = Object.entries(styleIDs).filter((el) => {
      if (el[1].includes(style)) {
        return el[0];
      }
    });
    const background = type.length > 0 ? type[0][0] : "white";
    return background;
  };

  //Grabs user from local state to have persistence of login
  //Fetches data from various routes on load
  useEffect(() => {
    console.log("APP STARTED");
    eventsFetch().then((res) => setEventsCalendar(res));
    dataFetch().then((res) => setData(res));
    blogFetch().then((res) => setBlogPosts(res));
    caresFetch().then((res) => setCaresPosts(res));
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      // console.log("FOUND TOKEN");
      setToken(loggedInUser);
    }
    // console.log("TOKEN", loggedInUser);
  }, []);

  // fire various effects on route change
  useEffect(() => {
    // const body = document.body;
    window.scrollTo({ top: 0, behavior: "instant" });
    setLoginVisible(false);
    setCalendarToggle(false);
    setNewsToggle(false);
    setHamburgerToggle(false);
    // console.log("DATA", data);
  }, [location]);

  const tokenSetter = (value) => {
    setToken(value);
  };

  const resumeSetter = (value) => {
    setResumeData(value);
  };

  const newsToggleSetter = (value) => {
    setNewsToggle(value);
  };

  const newsItemSetter = (value) => {
    setNewsItem(value);
  };

  const visibilitySetter = (value) => {
    setIsVisible(value);
  };

  return (
    <Parent
      id="print-parent"
      style={{ visibility: isVisible }}
      className={hamburgerToggle ? "no-scroll" : ""}
    >
      <Helmet>
        <title key="title">ABC Greater Baltimore</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <meta
          name="description"
          content="Workforce Development and Labor Relations"
        />
        <meta
          name="keywords"
          content="construction, workforce, education, jobs, training, apprenticeship"
        />
        {/* <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-RP1V6H0XCH`}
        /> */}
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-RP1V6H0XCH');`}
        </script>
        {/* Feather GTM Code */}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5HZSRVJC');`}</script>
      </Helmet>
      <Global styles={css(gutenburgStyle)} />
      <Global styles={css(gutenburgTheme)} />
      <Global styles={css(adminWelcome)} />
      <Global styles={css(editorBlocks)} />
      <Global styles={css(editorBlocksTwo)} />
      <Global styles={css(frontend_blocks)} />
      <GlobalStyle />
      {/* <CalendarContainer>
        <EventsCalendar
          calendarToggle={calendarToggle}
          setCalendarToggle={setCalendarToggle}
          eventsCalendar={eventsCalendar}
        />
      </CalendarContainer> */}
      {/* <NewsContainer>
        <NewsBar
          blogPosts={blogPosts}
          youTubePosts={youTubePosts}
          newsItem={newsItem}
          setNewsItem={newsItemSetter}
          newsToggle={newsToggle}
          setNewsToggle={newsToggleSetter}
          caresPosts={caresPosts}
        />
      </NewsContainer> */}

      <Header2
        blogPosts={blogPosts}
        // youTubePosts={youTubePosts}
        // setYouTubePosts={setYouTubePosts}
        caresPosts={caresPosts}
        eventsCalendar={eventsCalendar}
        hamburgerToggle={hamburgerToggle}
        setHamburgerToggle={setHamburgerToggle}
        loginVisible={loginVisible}
        setLoginVisible={setLoginVisible}
        token={token}
        setToken={tokenSetter}
        intViewportWidth={intViewportWidth}
      />

      <HomepageContainer className={hamburgerToggle ? "menu-open" : ""}>
        {hamburgerToggle ? (
          <MobileMenu
            expandedMenu={expandedMenu}
            setExpandedMenu={setExpandedMenu}
            hamburgerToggle={hamburgerToggle}
            setHamburgerToggle={setHamburgerToggle}
            token={token}
            setToken={setToken}
          />
        ) : null}
      </HomepageContainer>

      {/* <Header
        // style={window.location.pathname === "/" ? "alt" : "inner"}
        hamburgerToggle={hamburgerToggle}
        setHamburgerToggle={setHamburgerToggle}
        calendarToggle={calendarToggle}
        setCalendarToggle={setCalendarToggle}
        loginVisible={loginVisible}
        setLoginVisible={setLoginVisible}
        setToken={tokenSetter}
        newsToggle={newsToggle}
        setNewsToggle={setNewsToggle}
        youTubePosts={youTubePosts}
        setYouTubePosts={setYouTubePosts}
      /> */}

      {/* <ContentWrapper className="scrollable"> */}

      {/* {window.location.pathname === "/" ? null : (
          <SideMenu token={token} setToken={tokenSetter} style="" />
        )} */}
      <MainTag>
        {/* Feather GTM Code */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5HZSRVJC"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript>
        <AnimationWrapper>
          {transitions((props, item) => {
            return (
              <animated.div
                // id="scrollBody"
                className={window.location.pathname !== "/" ? "" : "homepage"}
                style={props}
              >
                <SpacingDiv
                  className={window.location.pathname !== "/" ? "" : "homepage"}
                >
                  <Routes>
                    {/* <Route
                        exact
                        path="/"
                        element={
                          <HomePage
                            expandedMenu={expandedMenu}
                            setExpandedMenu={setExpandedMenu}
                            hamburgerToggle={hamburgerToggle}
                            setHamburgerToggle={setHamburgerToggle}
                            token={token}
                            setToken={setToken}
                            youTubePosts={youTubePosts}
                            setYouTubePosts={setYouTubePosts}
                            newsToggle={newsToggle}
                            setNewsToggle={setNewsToggle}
                            calendarToggle={calendarToggle}
                            setCalendarToggle={setCalendarToggle}
                            intViewportWidth={intViewportWidth}
                          />
                        }
                      /> */}
                    <Route
                      exact
                      path="/"
                      element={
                        <HomePage2
                          eventsCalendar={eventsCalendar}
                          intViewportWidth={intViewportWidth}
                          token={token}
                          setToken={setToken}
                          setHamburgerToggle={setHamburgerToggle}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/login"
                      element={
                        <LoginPage token={token} setToken={tokenSetter} />
                      }
                    />
                    <Route
                      exact
                      path="/abc-cares/*"
                      element={<CaresBlog caresPosts={caresPosts} />}
                    />
                    <Route
                      exact
                      path="/academies"
                      element={<AcademiesPage />}
                    />
                    <Route
                      exact
                      path="/news/"
                      element={<BlogPage blogPosts={blogPosts} />}
                    />
                    <Route
                      exact
                      path="/news/*"
                      element={<Blog blogPosts={blogPosts} />}
                    />
                    <Route
                      exact
                      path="/spanish-speaking"
                      element={
                        <SpanishSpeakingPage
                          eventsCalendar={eventsCalendar}
                          data={data}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/excellence-in-construction"
                      element={<EICPage data={data} />}
                    />
                    <Route
                      exact
                      path="/events"
                      element={
                        <EventsPage
                          eventsCalendar={eventsCalendar}
                          data={data}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/events-secret"
                      element={
                        <EventsPublishPage
                          eventsCalendar={eventsCalendar}
                          data={data}
                        />
                      }
                    />
                    <Route exact path="/pdp" element={<PdpPage />} />
                    <Route
                      exact
                      path="/find-a-contractor"
                      element={
                        <SearchPage
                          data={data}
                          token={token}
                          setToken={tokenSetter}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/pay-invoice"
                      element={<InvoiceRedirect />}
                    />
                    <Route
                      exact
                      path="/about-us"
                      element={<AboutUsPage data={data} />}
                    />
                    <Route
                      exact
                      path="/logic-interest"
                      element={
                        <LogicInterestPage
                          data={data}
                          setResumeData={resumeSetter}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/logic-scholarship"
                      element={<LogicScholarshipPage data={data} />}
                    />
                    <Route
                      exact
                      path="/apprenticeship-application"
                      element={<ApprenticeAppPage />}
                    />
                    <Route
                      exact
                      path="/membership-interest"
                      element={<JoinABCPage data={data} />}
                    />
                    <Route
                      exact
                      path="/apprentice-resume"
                      element={
                        <ApprenticeResumePage
                          data={data}
                          setResumeData={resumeSetter}
                        />
                      }
                    />
                    <Route
                      exct
                      path="/resume-print"
                      element={
                        <ResumePrint
                          resumeData={resumeData}
                          isVisible={isVisible}
                          setIsVisible={visibilitySetter}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/virtual-hr"
                      element={
                        <VirtualHRPage
                          data={data}
                          setToken={tokenSetter}
                          token={token}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/benefit-book"
                      element={
                        <BenefitBookPage
                          data={data}
                          token={token}
                          setToken={tokenSetter}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/pro-sponsorship"
                      element={<ProSponsorPage data={data} />}
                    />
                    <Route
                      exact
                      path="/task-training"
                      element={<TaskTrainingPage data={data} />}
                    />
                    <Route
                      exact
                      path="/facility-rental"
                      element={<FacilityRentalPage data={data} />}
                    />
                    <Route
                      exact
                      path="/company-update"
                      element={<CompanyUpdatePage data={data} />}
                    />
                    <Route
                      exact
                      path="/school-data"
                      element={<SchoolDataPage data={data} />}
                    />
                    <Route
                      exact
                      path="/register"
                      element={<RegistrationPage data={data} />}
                    />
                    <Route
                      exact
                      path="/abc-cares"
                      element={
                        <ABCCaresPage data={data} caresPosts={caresPosts} />
                      }
                    />
                    <Route
                      exact
                      path="/save-money"
                      element={
                        <SaveMoneyPage intViewportWidth={intViewportWidth} />
                      }
                    />
                    <Route
                      exact
                      path="/signature-events"
                      element={<SignatureEventsPage data={data} />}
                    />
                    <Route path="*" element={<Page data={data} />}></Route>
                  </Routes>
                  <Footer intViewportWidth={intViewportWidth} />
                  {page[0] ? (
                    <>
                      <PageBackground className={backgroundStyle()} />
                    </>
                  ) : null}
                </SpacingDiv>
              </animated.div>
            );
          })}
        </AnimationWrapper>
      </MainTag>
      {/* </ContentWrapper> */}
    </Parent>
  );
}

export default App;
