import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const buttonStyles = css`
  background-color: var(--colors-menuBlue);
  color: white;
  border: none;
  border-radius: 8px;

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
`;

const invertedButtonStyles = css`
  background-color: white;
  color: black;
  border: none;
  border-radius: 8px;

  &:hover {
    background-color: black;
    color: white;
    border: none;
  }
`;

const newSiteButtonStyles = css`
  margin-top: 10px;
  background-color: var(--colors-site-lightAccent);
  border-radius: 30px;
  min-width: 230px;
  min-height: 45px;
  border: none;
  font-size: 1.5em;
  padding: 5px 25px;
  color: white;
  text-transform: none;
  font-weight: 400;
`;

const centered = css`
  display: block;
  margin: 0 auto;
`;

const getButtonStyles = (props) => {
  if (props.newSiteButton) {
    return newSiteButtonStyles;
  }

  return props.inverted ? invertedButtonStyles : buttonStyles;
};

const getCentered = (props) => {
  return props.centered ? centered : null;
};

export const CustomButtonContainer = styled.button`
  min-width: 150px;
  width: auto;
  min-height: 45px;
  padding: 0 35px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 20px;

  ${getButtonStyles}
  ${getCentered}
`;
