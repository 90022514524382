import styled from "@emotion/styled";
import { fullFrameDiv } from "../../styles/global-variables.styles";
import Guy from "../../static/images/guy.jpg";

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${Guy});
  background-position: center center;
  background-size: cover;
  z-index: -1;
`;

export const ErrorContainer = styled.div`
  height: 50vh;
  padding: 10vw;
  font-size: 1.2em;
  color: var(--colors-site-white);
  display: flex;
  align-items: center;
  margin-left: 60px;
`;
export const HeadlineWrapper = styled.div`
  position: relative;
`;
