import styled from "@emotion/styled";

export const FooterWrapper = styled.div`
  width: 100vw;
  background-color: var(--colors-site-white);
  padding-bottom: 100px;
  overflow-x: hidden;
  @media screen and (max-width: 1000px) {
    padding-left: 0px;
  }
`;

export const ScrollTopContainer = styled.div`
  width: 100%;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 25px 0px;
    cursor: pointer;
    line-height: 0.8;
  }
`;

export const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 200px;
  background-color: var(--colors-site-white);
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-width: 1000px) {
    min-height: 400px;
    flex-direction: column;
    padding: 0 0 10px 0;
    flex-grow: 3;
  }

  @media screen and (max-width: 767px) {
    _::-webkit-full-page-media,
    _:future,
    :root .safari_only {
      padding-bottom: 74px; //resize
    }
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 0.8em;
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin: 0;
  }
  /* @media screen and (max-width: 800px){
    display: none;
  } */
`;

export const Copyright = styled.p`
  margin-top: 40px;
  padding-bottom: 30px;
`;

export const Address = styled.p`
  width: 280px;
  font-family: "Poppins", sans-serif;
  /* font-size: 12px; */
  text-align: center;
  margin-top: 15px;
  margin-bottom: 20px;
`;
