import React, { useState } from "react";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";
import registerUser from "../../actions/register-user.actions";
import forgottenPassword from "../../actions/forgotten-password.actions";

import {
  LoginContainer,
  ErrorMessage,
  ForgottenButton,
  SuccessMessage,
} from "./register.styles";

const Register = ({}) => {
  const [forgotten, setForgotten] = useState(false);
  const [recovered, setRecovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userName, setUserName] = useState();
  const [userPass, setUserPass] = useState();
  const [registrationSuccess, setRegistrationSuccess] = useState();
  const [registrationError, setRegistrationError] = useState();
  const [loginError, setLoginError] = useState();
  const [userFirst, setUserFirst] = useState();
  const [userLast, setUserLast] = useState();
  const [userCompany, setUserCompany] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    await registerUser(
      userName,
      userEmail,
      userPass,
      userFirst,
      userLast,
      userCompany
    ).then((res) => {
      if (res === true) {
        setRegistrationSuccess(true);
      } else {
        setRegistrationSuccess(false);
        setRegistrationError(res);
      }
    });
    if (!registrationSuccess) {
    } else {
      setUserEmail("");
      setUserName("");
      setUserPass("");
    }
  };

  const handlePassword = () => {
    setForgotten(true);
  };

  const handleForgottenPassword = async () => {
    const passwordUrl = `https://dev.abcbaltimore.org/wp-json/wp/v2/users/lost-password`;
    let x = await forgottenPassword(passwordUrl, userName);
    // console.log(x)
    if (x === true) {
      setRecovered(true);
      setForgotten(false);
      setErrorMessage();
    } else {
      setErrorMessage(x.message);
    }
  };

  return (
    <LoginContainer>
      <h3>Register</h3>
      <p>
        Please note: All registrations will remain pending until approved by ABC
        staff. You will receive an email confirmation when your registration is
        approved.
      </p>
      {registrationSuccess === true ? (
        <h4>Your Registration is being Processed</h4>
      ) : (
        <form onSubmit={handleSubmit}>
          {recovered ? (
            <SuccessMessage>
              Please Check Your Email for Password Reset Instructions
            </SuccessMessage>
          ) : null}
          <FormInput
            className={
              registrationError && registrationError.includes("username")
                ? "error"
                : ""
            }
            name="email"
            type="email"
            value={userEmail}
            handleChange={(e) => {
              setUserEmail(e.target.value);
              setUserName(e.target.value);
            }}
            label="email"
            required
          />
          {registrationError && registrationError.includes("username") ? (
            <ErrorMessage>Sorry, that Email already exists.</ErrorMessage>
          ) : null}

          <FormInput
            className={
              registrationError && registrationError.includes("password")
                ? "error"
                : ""
            }
            name="password"
            type="password"
            value={userPass}
            handleChange={(e) => setUserPass(e.target.value)}
            label="create password"
            required
          />
          {registrationError && registrationError.includes("password") ? (
            <ErrorMessage>{registrationError}</ErrorMessage>
          ) : null}
          <FormInput
            name="first_name"
            type="text"
            value={userFirst}
            handleChange={(e) => setUserFirst(e.target.value)}
            label="first name"
            required
          />
          <FormInput
            name="last_name"
            type="text"
            value={userLast}
            handleChange={(e) => setUserLast(e.target.value)}
            label="last name"
            required
          />
          <FormInput
            name="company"
            type="text"
            value={userCompany}
            handleChange={(e) => setUserCompany(e.target.value)}
            label="company name"
            required
          />
          <CustomButton type="submit">Register</CustomButton>
          <br />
          <br />
          <ForgottenButton onClick={handlePassword}>
            Forgot Password
          </ForgottenButton>
        </form>
      )}

      {forgotten ? (
        <>
          <br />
          <br />
          <FormInput
            className={
              loginError && loginError.includes("email") ? "error" : ""
            }
            name="email"
            type="userName"
            value={userName}
            handleChange={(e) => setUserName(e.target.value)}
            label="email"
            required
          />
          <CustomButton onClick={handleForgottenPassword}>
            Recover Password
          </CustomButton>
          <br />
          <br />
          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </>
      ) : null}
    </LoginContainer>
  );
};

export default Register;
