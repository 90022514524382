import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { myunescape } from "../../utils/utility-functions";

import Page from "../../components/page/page.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import clock from "../../static/images/clock.png";

import {
  FeaturedContainer,
  EventsPageWrapper,
  FeaturedImage,
  CalendarWrapper,
  EventList,
  EventContainer,
  DateContainer,
  Day,
  Month,
  Date,
  InfoContainer,
  EventTitle,
  TimeContainer,
} from "./eventspublishpage.styles";

const EventsPublishPage = ({ eventsCalendar, data }) => {
  const [sortedCalendar, setSortedCalendar] = useState([]);

  console.log("EVENTSCALENDAR", eventsCalendar);

  useEffect(() => {
    if (eventsCalendar && eventsCalendar.length > 0) {
      console.log("HAS LENGTH");
      const sortingCalendar = [...eventsCalendar].sort((a, b) => {
        // b.modified - a.modified;
        if (a.modified > b.modified) {
          return -1;
        }
        if (a.modified < b.modified) {
          return 1;
        }
        return 0;
      });
      console.log("SORTING CALENDAR", sortingCalendar);
      setSortedCalendar(sortingCalendar);
    } else {
      console.log("NO LENGTH");
    }
  }, [eventsCalendar]);

  return (
    <>
      <Page data={data} />
      <EventsPageWrapper>
        <CalendarWrapper>
          <h3>Upcoming Events and Training</h3>
          {sortedCalendar && sortedCalendar.length > 0
            ? sortedCalendar.map((items) => {
                // {items.categories[0]? console.log("NAME", items.categories[0].name): null }
                // {items.categories[0] && items.categories[0].name === 'unlisted' ? null : console.log(items)}
                return (
                  <>
                    {items.categories[0] &&
                    items.categories.some(
                      (cat) => cat.name === "unlisted"
                    ) ? null : (
                      <EventList key={`{Math.random()} ${items.id}`}>
                        <a target="_blank" href={items.url}>
                          <EventContainer>
                            <DateContainer>
                              <Day>
                                {dayjs(`${items.start_date}`)
                                  .format("ddd")
                                  .toUpperCase()}
                              </Day>
                              <Month>
                                {dayjs(`${items.start_date}`).format("MMM")}
                              </Month>
                              <Date>
                                {dayjs(`${items.start_date}`).format("D")}
                              </Date>
                            </DateContainer>
                            {items.image ? (
                              <FeaturedImage>
                                <img src={items.image.url} />
                              </FeaturedImage>
                            ) : null}
                            <InfoContainer>
                              <EventTitle>{myunescape(items.title)}</EventTitle>
                              <TimeContainer>
                                <img
                                  src={clock}
                                  width="12px"
                                  height="12px"
                                ></img>
                                <p>
                                  {dayjs(`${items.start_date}`).format("ha")}-
                                  {dayjs(`${items.end_date}`).format("ha")}
                                </p>
                                {/* <p>{myunescape(items.description).split('@ ').join('').split(' ').slice(1,20).join(' ')}...</p> */}
                                {/* <div dangerouslySetInnerHTML={{ __html: items.description }} /> */}
                              </TimeContainer>
                            </InfoContainer>
                          </EventContainer>
                        </a>
                      </EventList>
                    )}
                  </>
                );
              })
            : null}
          <a href="https://events.abcbaltimore.org" target="_blank">
            <CustomButton link="https://events.abcbaltimore.org">
              See Full Calendar
            </CustomButton>
          </a>
        </CalendarWrapper>
      </EventsPageWrapper>
    </>
  );
};

export default EventsPublishPage;
