import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { myunescape } from "../../utils/utility-functions";
import { eventsFetch } from "../../utils/events-fetch";

import Page from "../../components/page/page.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import clock from "../../static/images/clock.png";

import {
  EventsPageWrapper,
  FeaturedImage,
  CalendarWrapper,
  ClassList,
  EventList,
  EventContainer,
  DateContainer,
  Day,
  Month,
  Date,
  InfoContainer,
  EventTitle,
  TimeContainer,
} from "./spanishspeakingpage.styles";

const SpanishSpeakingPage = ({ eventsCalendar, data }) => {
  const [spanishList, setSpanishList] = useState([]);

  useEffect(() => {
    eventsFetch("&categories=70").then((res) => setSpanishList(res));
  }, []);

  return (
    <>
      <Page data={data} />
      <EventsPageWrapper>
        <CalendarWrapper>
          <h3 className="white-text">Próximos Eventos y Capacitación</h3>
          {spanishList && spanishList.length > 0 ? (
            spanishList.map((items) => {
              // {items.categories[0]? console.log("NAME", items.categories[0].name): null }
              // {items.categories[0] && items.categories[0].name === 'unlisted' ? null : console.log(items)}
              return (
                <React.Fragment key={Math.random()}>
                  {items.categories[0] &&
                  items.categories.some(
                    (cat) => cat.name === "unlisted"
                  ) ? null : (
                    <EventList>
                      <a target="_blank" href={items.url}>
                        <EventContainer>
                          {/* <DayContainer>
                
                             </DayContainer> */}
                          <DateContainer>
                            <Day>
                              {dayjs(`${items.start_date}`)
                                .format("ddd")
                                .toUpperCase()}
                            </Day>
                            <Month>
                              {dayjs(`${items.start_date}`).format("MMM")}
                            </Month>
                            <Date>
                              {dayjs(`${items.start_date}`).format("D")}
                            </Date>
                          </DateContainer>
                          {items.image ? (
                            <FeaturedImage>
                              <img src={items.image.url} />
                            </FeaturedImage>
                          ) : null}
                          <InfoContainer>
                            <EventTitle>{myunescape(items.title)}</EventTitle>
                            <TimeContainer>
                              <img src={clock} width="12px" height="12px"></img>
                              <p>
                                {dayjs(`${items.start_date}`).format("ha")}-
                                {dayjs(`${items.end_date}`).format("ha")}
                              </p>
                              {/* <p>{myunescape(items.description).split('@ ').join('').split(' ').slice(1,20).join(' ')}...</p> */}
                              {/* <div dangerouslySetInnerHTML={{ __html: items.description }} /> */}
                            </TimeContainer>
                          </InfoContainer>
                        </EventContainer>
                      </a>
                    </EventList>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <>
              <p className="white-text">
                Actualmente no hay clases próximas. Las clases disponibles
                incluyen:
              </p>
              <ClassList>
                <li>OSHA 10-Hora</li>
                <li>OSHA 30-Hora</li>
                <li>Introducción a la Lectura de Planos</li>
                <li>CPR en Español</li>
              </ClassList>
              <p className="white-text">
                Para obtener más información o personalizar una clase,
                comuníquese con:{" "}
                <a href="mailto:bmattox@abcbaltimore.org">Bonnie Mattox</a>
              </p>
            </>
          )}
          <a href="https://events.abcbaltimore.org" target="_blank">
            <CustomButton>Ver el Calendario Completo de Eventos</CustomButton>
          </a>
        </CalendarWrapper>
      </EventsPageWrapper>
    </>
  );
};

export default SpanishSpeakingPage;
