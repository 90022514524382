import { useState } from "react";

const registerUser = async (
  userName,
  userEmail,
  userPass,
  userFirst,
  userLast,
  userCompany
) => {
  const tokenFetch = await fetch(
    `https://dev.abcbaltimore.org/wp-json/jwt-auth/v1/token`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        username: process.env.REACT_APP_DEV_USERNAME,
        password: process.env.REACT_APP_DEV_PASS,
      }),
    }
  );

  const token = await tokenFetch.json();
  // console.log("register-user token", token.token);

  const res = await fetch(`https://dev.abcbaltimore.org/wp-json/wp/v2/users`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    }),
    body: JSON.stringify({
      username: userName,
      email: userEmail,
      password: userPass,
      first_name: userFirst,
      last_name: userLast,
      description: userCompany,
    }),
    redirect: "follow",
  });

  const body = await res.json();
  console.log("body", body);

  if (body.id) {
    return true;
  } else {
    return body.message;
  }
  // console.log("REG ERROR", state.theme.registrationError);
};

export default registerUser;
