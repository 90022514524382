import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const ThreeColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  gap: 20px;
  padding: 20px;
`;

export const Column = styled.div`
  flex: 0 1 400px;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const ColumnImage = styled.img`
  width: 400px;
  height: 300px;
  object-fit: cover;

  &.contain {
    object-fit: contain;
  }
`;
