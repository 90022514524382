import * as XLSX from "xlsx";

const convertJSONtoXLSX = (jsonData) => {
  // Convert JSON data to worksheet format
  const worksheet = XLSX.utils.json_to_sheet(jsonData);

  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Convert the workbook to a binary string
  const workbookBinaryString = XLSX.write(workbook, {
    type: "binary",
    bookType: "xlsx",
  });

  // Convert the binary string to a Blob
  const workbookBlob = new Blob([s2ab(workbookBinaryString)], {
    type: "application/octet-stream",
  });

  // Create a download link and trigger the download
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(workbookBlob);
  downloadLink.download = "ABC_Contractor_Data.xlsx";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

// Helper function to convert string to ArrayBuffer
const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export default convertJSONtoXLSX;
