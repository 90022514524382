import styled from "@emotion/styled";

export const EventsPageWrapper = styled.div`
  width: var(--screen-width);
  /* margin-left: 150px; */

  @media screen and (max-width: 1100px) {
    width: 100vw;
    padding: 20px;
  }
`;

export const CalendarWrapper = styled.div`
  padding: 40px 40px;
  width: 100%;
  // background-color: var(--colors-site-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 800px) {
    width: 100vw;
    margin-left: -20px;

    h3 {
      padding-left: 5px;
    }
  }
`;

export const EventList = styled.div`
  margin-top: 10px;
  background-color: var(--colors-site-white);
  width: 60%;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const EventContainer = styled.div`
  display: flex;
  border-bottom: 2px solid var(--colors-site-mainTheme);
  overflow: hidden;
  height: 150px;
  transition: all 0.1s;
  align-items: center;
  padding: 5px 0px;
  /* white-space: nowrap; */

  &:hover {
    background-color: white;
    border-left: 5px solid red;
  }

  @media screen and (max-width: 600px) {
    height: auto;
    min-height: 150px;
  }
`;

export const FeaturedImage = styled.div`
  margin: 0px 20px;
  width: 200px;
`;

export const DateContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--colors-site-mainTheme);
  line-height: 1.2;
`;

export const Day = styled.p`
  margin: 0;
`;

export const Month = styled.p`
  font-size: 1.5em;
  margin: 0;
`;

export const Date = styled.p`
  font-size: 1.5em;
  margin: 0;
`;

export const InfoContainer = styled.div`
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  a {
    text-decoration: none;
    color: var(--colors-site-lightAccent);
  }
  line-height: 1;

  white-space: normal;
  overflow: hidden;
`;

export const EventTitle = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1;
  margin: 0px;
  color: var(--colors-site-lightAccent);
`;

export const TimeContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  color: var(--colors-site-mainTheme);

  p {
    margin: 0px;
    margin-left: 5px;
  }
`;

export const ClassList = styled.ul`
  li {
    color: var(--colors-site-white);
  }
`;
