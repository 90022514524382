import styled from "@emotion/styled";

export const SocialRow = styled.div`
  width: 280px;
  position: relative;
  height: 40px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
`;

export const Facebook = styled.div`
  position: absolute;
  left: 0;
  border-radius: 50%;
  transition: all 0.5s;

  &:hover {
    border: 1px solid hsl(220, 44%, 41%);
    transform: scale(1.2);
    background-color: hsl(220, 44%, 41%);

    img {
      filter: invert(90%);
    }
  }
`;

export const Youtube = styled.div`
  position: absolute;
  left: 62px;
  border-radius: 50%;
  transition: all 0.5s;

  &:hover {
    border: 1px solid crimson;
    transform: scale(1.2);
    background-color: crimson;

    img {
      filter: invert(100%);
    }
  }
`;

export const Twitter = styled.div`
  position: absolute;
  left: 125px;
  border-radius: 50%;
  transition: all 0.5s;

  &:hover {
    border: 1px solid deepskyblue;
    transform: scale(1.2);
    background-color: deepskyblue;

    img {
      filter: invert(100%);
    }
  }
`;

export const Instagram = styled.div`
  position: absolute;
  left: 189px;
  border-radius: 50%;
  transition: all 0.5s;

  &:hover {
    border: 1px solid black;
    transform: scale(1.2);
    background-color: black;

    img {
      filter: invert(100%);
    }
  }
`;

export const LinkedIn = styled.div`
  position: absolute;
  left: 250px;
  border-radius: 50%;
  transition: all 0.5s;

  &:hover {
    border: 1px solid darkcyan;
    transform: scale(1.2);
    background-color: darkcyan;

    div {
      filter: invert(100%);
    }
  }
`;
