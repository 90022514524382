import React from "react";
import { Link } from "react-router-dom";

import {
  TitleBlock,
  AccentLineMain,
  FooterBlock,
  TwoColumnGrid,
} from "../../App.styles";

import Page from "../../components/page/page.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import { Wrapper, ImageBlock, TryptychImage, Tryptych } from "./eicpage.styles";

const EICPage = ({}) => {
  return (
    <Wrapper>
      <TitleBlock className="reverse">
        <div style={{ minHeight: 100 + "%" }}>
          <ImageBlock src="https://dev.abcbaltimore.org/wp-content/uploads/2023/10/EIC_NoYear-2048x874.png" />
        </div>
        <div>
          <h1 className="newSiteHeading">Excellence In Construction</h1>
          <p>
            ABC Greater Baltimore’s Excellence in Construction program is a
            yearly self-nominating award in which general and specialty
            contractors submit their projects for recognition. The EIC program
            is the premier competition within the region that recognizes
            outstanding projects ABC members have built.
            <br />
            Each year, members nominate their best projects describing the
            unique characteristics and quality of their craftsmanship.
            Applications are judged by impartial members of the construction
            industry, including engineers and architects, according to the
            criteria for each award.
          </p>
        </div>
      </TitleBlock>
      <div
        style={{
          width: "100%",
          justifySelf: "start",
          paddingLeft: "10%",
          marginTop: "50px",
          backgroundColor: "var(--colors-site-mainTheme)",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <p style={{ width: "60%" }} className="white-text">
          IMPORTANT DATES TO REMEMBER: <br />
          Deadline for entry: Friday, March 15th, 2024 <br />
          Judging day: Thursday, May 16th, 2024 <br />
          <a
            target="_blank"
            href="https://events.abcbaltimore.org/event/2024-abc-excellence-in-construction-awards-program/"
          >
            Awards Presentation
          </a>
          : Thursday, October 10th, 2024 at The Hall at Maryland LIVE! Casino
        </p>
        <TwoColumnGrid className="sixty">
          <div>
            <Link to="/news/29916/">
              <CustomButton newSiteButton>See This Year's Winners</CustomButton>
            </Link>
            <Link to="https://mydigitalpublication.com/publication/?i=805491">
              <CustomButton newSiteButton>2023 Awards Magazine</CustomButton>
            </Link>
            <Link to="https://awards.abcbaltimore.org/">
              <CustomButton newSiteButton>Judge's Registration</CustomButton>
            </Link>
          </div>
          <div>
            <Link to="https://www.facebook.com/media/set/?set=a.807346874730512&type=3">
              <CustomButton newSiteButton>
                Check out our Facebook Album
              </CustomButton>
            </Link>
            <Link to="https://awards.abcbaltimore.org/">
              <CustomButton newSiteButton>
                EIC Application / Eligibility
              </CustomButton>
            </Link>
          </div>
        </TwoColumnGrid>
      </div>
      <br />
      <div>
        <h3 className="white-text">EIC Resources</h3>
        <Link to="https://youtu.be/Lk-ldg-75xs">
          <CustomButton newSiteButton>
            Tutorial: How to Submit an Award-Winning Project
          </CustomButton>
        </Link>
      </div>
      <Tryptych>
        <TryptychImage
          src="https://dev.abcbaltimore.org/wp-content/uploads/2022/06/279374184_10159640986818604_5173074785664330329_n-1024x684.jpg"
          alt=""
        />
        <TryptychImage
          src="https://dev.abcbaltimore.org/wp-content/uploads/2022/06/279404100_10159640986638604_4382653790097886508_n-1024x684.jpg"
          alt=""
        />
        <TryptychImage
          src="https://dev.abcbaltimore.org/wp-content/uploads/2022/06/279301797_10159640997858604_947640995104368892_n-1024x771.jpg"
          alt=""
        />
      </Tryptych>
      <FooterBlock>
        <h2 className="newSiteHeading">
          Grow your career with us. Be more with ABC Baltimore.
        </h2>
      </FooterBlock>
    </Wrapper>
  );
};

export default EICPage;
