const fetchToken = async (userName, userPass, loginErrorSetter) => {
  const res = await fetch(
    `https://dev.abcbaltimore.org/wp-json/jwt-auth/v1/token`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        username: userName,
        password: userPass,
      }),
      redirect: "follow",
    }
  );
  const body = await res.json();
  console.log("body", body);
  console.log("body token", body.token);
  if (body.token !== undefined) {
    localStorage.setItem("user", body.token);
    return body.token;
  } else if (body.code.includes("invalid")) {
    console.log("invalid");
    loginErrorSetter("invalid username");
  } else if (body.code.includes("incorrect")) {
    console.log("incorrect");
    loginErrorSetter("incorrect password");
  } else {
    return;
  }
};

export default fetchToken;
