import React, { useEffect, useState } from "react";
import {
  BlogWrapper,
  GlassWrap,
  NewsTitle,
  LeftBar,
  LogoWrapper,
} from "./blog.styles";
import { Link } from "react-router-dom";

import LogoContainer from "../logo-container/logo-container.component";
import { myunescape } from "../../utils/utility-functions";

import abcCares from "../../static/images/ABC_Cares_Crop_2.png";

const CaresBlog = ({ caresPosts }) => {
  const [blogPost, setBlogPost] = useState();

  const blogID = window.location.pathname.split("/")[2];
  // console.log("blog ID", blogID)

  useEffect(() => {
    if (caresPosts.length > 0) {
      // console.log("CARES", state.theme.caresPosts);

      caresPosts.map((el) => {
        // console.log(el.id);
        if (el.id.toString() === blogID) {
          // console.log("ELEMENT", el)
          setBlogPost(el);
        }
      });
    }
  }, [caresPosts]);

  return (
    <>
      {blogPost !== undefined ? (
        <GlassWrap>
          <BlogWrapper>
            <LogoWrapper>
              <Link to="/abc-cares">
                <LogoContainer
                  className="centered"
                  source={abcCares}
                  alt="ABC Logo"
                  widthValue="400px"
                  heightValue="auto"
                  link="/abc-cares"
                  margin="0"
                />
              </Link>
            </LogoWrapper>
            <h4>
              Building a Greater Baltimore One Project, Community, and Career at
              a Time
            </h4>
            <img src={blogPost.featured_image_urls_v2.large[0]} />
            <br />
            <h3>{myunescape(blogPost.title.rendered)}</h3>
            <br />
            <div
              dangerouslySetInnerHTML={{ __html: blogPost.content.rendered }}
            />
          </BlogWrapper>
        </GlassWrap>
      ) : null}
    </>
  );
};

export default CaresBlog;
