import React, { useEffect, useState } from "react";

import { generalFetch } from "../../utils/events-fetch";

import Page from "../../components/page/page.component";

const SchoolDataPage = ({ data }) => {
  const [schoolData, setSchoolData] = useState({});

  useEffect(() => {
    generalFetch(
      "https://dev.abcbaltimore.org/wp-json/gf/v2/forms/4/entries"
    ).then((res) => setSchoolData(res));
  }, []);

  return (
    <>
      <Page data={data} />
      {schoolData ? (
        <div>
          FOUND DATA
          {console.log(schoolData)}
        </div>
      ) : null}
    </>
  );
};

export default SchoolDataPage;
