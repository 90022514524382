import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import forgottenPassword from "../../actions/forgotten-password.actions";
import fetchToken from "../../actions/fetch-token.actions";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";

import {
  LoginContainer,
  ErrorMessage,
  RegisterLink,
  LoginForm,
  LoginText,
  SuccessMessage,
  ForgottenButton,
} from "./login.styles";

const Login = ({ token, setToken }) => {
  const [forgotten, setForgotten] = useState(false);
  const [recovered, setRecovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loginError, setLoginError] = useState("");
  const [userName, setUserName] = useState("");
  const [userPass, setUserPass] = useState("");

  const navigate = useNavigate();

  const loginErrorSetter = (value) => {
    const newError = value;
    setLoginError(newError);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fetchedToken = await fetchToken(userName, userPass, loginErrorSetter);
    if (fetchedToken) {
      setToken(fetchedToken);
      setUserPass("");
      if (window.location.pathname === "/login") {
        navigate("/members");
      }
    }
  };

  const handleClick = () => {
    setForgotten(true);
  };

  const handleForgottenPassword = async () => {
    const passwordUrl = `https://dev.abcbaltimore.org/wp-json/wp/v2/users/lost-password`;
    let x = await forgottenPassword(passwordUrl, userName);
    // console.log(x)
    if (x === true) {
      setUserName("");
      setRecovered(true);
      setForgotten(false);
      setErrorMessage();
    } else {
      setErrorMessage(x.message);
    }
  };

  return (
    <LoginContainer className="">
      <LoginText>
        LOG IN TO YOUR ACCOUNT <br />
        All ABC Members can create an account to gain access to members-only
        features.
        <br />
        <br />
        Don't have an account?
      </LoginText>
      <Link to="/register">
        <CustomButton>Create Account</CustomButton>
      </Link>
      <LoginForm
        onSubmit={handleSubmit}
        className={forgotten ? "nodisplay" : ""}
      >
        {recovered ? (
          <SuccessMessage>
            Please Check Your Email for Password Reset Instructions
          </SuccessMessage>
        ) : null}
        <FormInput
          className={loginError.includes("email") ? "error" : ""}
          id="loginEmail"
          name="email"
          type="userName"
          value={userName}
          handleChange={(e) => setUserName(e.target.value)}
          label="email"
          required
        />
        {loginError.includes("username") ? (
          <ErrorMessage>{loginError}</ErrorMessage>
        ) : null}
        <FormInput
          className={loginError.includes("password") ? "error" : ""}
          id="loginPassword"
          style="eye"
          name="password"
          type="password"
          value={userPass}
          handleChange={(e) => setUserPass(e.target.value)}
          label="password"
          required
        />
        {loginError.includes("password") ? (
          <ErrorMessage>{loginError}</ErrorMessage>
        ) : null}
        <CustomButton type="submit">Log In</CustomButton>
        <br />
        <ForgottenButton onClick={handleClick}>Forgot Password</ForgottenButton>
      </LoginForm>
      {forgotten ? (
        <>
          <br />
          <br />
          <FormInput
            className={loginError.includes("email") ? "error" : ""}
            name="email"
            type="userName"
            value={userName}
            handleChange={(e) => setUserName(e.target.value)}
            label="email"
            required
          />
          <CustomButton onClick={handleForgottenPassword}>
            Recover Password
          </CustomButton>
          <br />
          <br />
          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </>
      ) : null}
    </LoginContainer>
  );
};

export default Login;
