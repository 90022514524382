import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const Paragraph = styled.div`
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 800px) {
    padding: 50px 10%;
  }

  &.blue {
    background-color: var(--colors-site-mainTheme);
    p,
    li,
    h3 {
      color: var(--colors-site-white);
    }
  }
`;
