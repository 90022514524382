import React from "react";

import Login from "../../components/login/login.component";
import { ImageWrapper, Wrapper, LoginWrapper } from "./loginpage.styles";

const LoginPage = ({ token, setToken }) => {
  return (
    <Wrapper>
      <ImageWrapper />
      <LoginWrapper>
        <Login setToken={setToken} />
      </LoginWrapper>
    </Wrapper>
  );
};

export default LoginPage;
