import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const LoginContainer = styled.div`
  height: 80%;
  width: 60%;
  z-index: 99;
  padding: 40px 40px 80px 40px;
  transition: all 0.5s;
  background-color: white;

  @media screen and (max-width: 1100px) {
    width: 80%;
  }
`;

export const LoginText = styled.p`
  font-family: "Poppins";
`;

export const LoginForm = styled.form`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &.nodisplay {
    display: none;
  }
`;

export const ErrorMessage = styled.p`
  padding: 2px;
  color: red !important;
`;

export const SuccessMessage = styled.p`
  padding: 2px;
  color: green;
`;

export const RegisterLink = styled(Link)`
  text-decoration: none;
  color: var(--colors-menuBlue);
`;

export const ForgottenButton = styled.a`
  cursor: pointer;
`;
